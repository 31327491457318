import * as React from 'react'
import { useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image'
import { ComMain } from '@components/index'
import { FeedInFunc } from '@func/index'
import Nippo from '@svgs/logo_nippo.svg'
import HasekoCorporation from '@svgs/haseko_corporation.svg'
import HasekoArvest from '@svgs/haseko_arvest.svg'
import Lecinq from '@svgs/logo_lecinq.svg'
import CompanyGraph from '@svgs/company_graph.svg'

const CompanyPage = () => {

  useEffect(() => {
    FeedInFunc();
  }, []);

  return (
    <main className='mainCompany'>
      {/* CONTENTS */}

      <ComMain
        ttl="COMPANY"
        subttl="会社紹介"
        caption="image photo"
      >
        <StaticImage
          src="../assets/images/company/main.jpg"
          alt=""
          className="_pc"
        />
        <StaticImage
          src="../assets/images/company/main_sp.jpg"
          alt=""
          className="_sp"
        />
      </ComMain>

      <section className='sec-nippo'>
        <div className="c-content">

          <div className="logo-wrap comFeed">
            <div className="logo-box">
              <Nippo
                alt="NIPPO"
              />
            </div>
            {/* <p className='ttl'>NIPPO ル・サンクブランド</p> */}
          </div>

          <div className="lead-wrap comFeed">
            <p className="ttl">わたしたちは確かな<br className='_sp' />ものづくりを通して<br />豊かな社会の実現に貢献します。</p>
            <p className="txt">NIPPOの歴史は、日本の道路舗装の歴史です。<br />当社は、昭和9年2月設立ですが、その前身をたどれば明治40年まで遡り、100年以上の歴史を数えます。<br />その時代の最先端で最高の技術を駆使し、我が国舗装技術のパイオニアとして時代をリードしてきました。<br />今日では、中核事業である舗装・土木事業、製品製造・販売事業をはじめ、<br className="_pc" />建築工事、開発事業、土壌浄化事業を幅広く展開し、<br className="_pc" />地球環境や日本の国土開発、そして地域のよりよい生活環境を創造する会社として、未来に向かって歩み続けています。</p>
          </div>

          <div className="lecinq-wrap comFeed">
            <div className="lecinq-box">
              <div className="logo">
                <Lecinq
                  alt="LeCinq"
                />
              </div>
              <p className="ttl">居住者の大切な資産を造り上げる<br />「ル・サンクシリーズ」</p>
              <p className="txt">NIPPOのマンション事業は、Access・Nature・Space・Location・Securityの5つの価値を重視し、『ル・サンク（Le Cinq）シリーズ』として展開しております。<br className="_sp" />また、当社単独事業の他、他社との共同事業や地権者との等価交換での共同事業、土地の有効活用、資産活用の視点からの提案事業など、コンサルティング業務を重ねながら、的確なマーケティングによる事業計画、建築計画を実行します。<br className="_sp" />その地域に最適と思われるプランニングを推進し、『豊かな住まい』の創造をしております。</p>

              <div className="flex">
                <div className="list list1">
                  <StaticImage
                    src="../assets/images/company/i_1.jpg"
                    alt=""
                  />
                  <p>ル・サンク戸塚ブランシエラ<br />（神奈川県横浜市戸塚区）</p>
                </div>
                <div className="list list2">
                  <StaticImage
                    src="../assets/images/company/i_2.jpg"
                    alt=""
                  />
                  <p>ル・サンク東京森下<br />（東京都墨田区）</p>
                </div>
                <div className="list list3">
                  <StaticImage
                    src="../assets/images/company/i_3.jpg"
                    alt=""
                  />
                  <p>インプレスト東京八丁堀ル・サンク<br />（東京都中央区）</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-corporation'>
        <div className="c-content">

          <div className="logo-wrap comFeed">
            <div className="logo-box">
              <HasekoCorporation
                alt="長谷工 コーポレーション"
              />
            </div>
            {/* <p className='ttl'>長谷工 コーポレーション</p> */}
          </div>

          <div className="lead-wrap comFeed">
            <p className="ttl">信頼の実績を確かな実践へ。</p>
            <p className="txt">長谷工コーポレーションはマンション建設で業界トップの累計施工実績約67万戸以上を誇ります。<br />「いい暮らしを創る。住まいのオンリーワングループ」をスローガンに、<br className='_pc' />長く安心して暮せる快適な住まいの提供に努めております。</p>
          </div>

          <div className="data-wrap">
            <div className="data-box">
              <div className="list m">
                <p className="ttl">マンション施工実績</p>
                <p className="main">No.1</p>
                {/* <p className="txt"></p> */}
                {/* <p className="note"></p> */}
              </div>
              <div className="list m">
                <p className="ttl">マンション施工累計実績</p>
                <p className="main">677,684<span>戸</span></p>
                {/* <p className="txt"></p> */}
                <p className="note">※2021年11月末現在</p>
              </div>
              <div className="list m">
                <p className="ttl">住まいのオンリーワングループ</p>
                {/* <p className="main"></p> */}
                {/* <p className="txt"></p> */}
                {/* <p className="note"></p> */}
              </div>
              <div className="list m">
                <p className="ttl">海外住宅にも進出・アジア進出</p>
                {/* <p className="main"></p> */}
                {/* <p className="txt"></p> */}
                {/* <p className="note"></p> */}
              </div>
            </div>
          </div>

          <div className="gallery-wrap">
            <div className="gallery-box">
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/company/i_4.jpg"
                    alt=""
                  />
                </div>
                <p className="txt">深沢ハウス</p>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/company/i_5.jpg"
                    alt=""
                  />
                </div>
                <p className="txt">ブランシエラ浦和</p>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/company/i_6.jpg"
                    alt=""
                  />
                </div>
                <p className="txt">深大寺レジデンス</p>
              </div>
              <div className="list">
                <div className="img">
                  <StaticImage
                    src="../assets/images/company/i_7.jpg"
                    alt=""
                  />
                </div>
                <p className="txt">ブランシエラ一之江</p>
              </div>
            </div>
          </div>

        </div>
      </section>


      <section className='sec-arvest'>
        <div className="c-content">

          <div className="logo-wrap comFeed">
            <div className="logo-box">
              <HasekoArvest
                alt="長谷工 アーベスト"
              />
            </div>
            {/* <p className='ttl'>長谷工 アーベスト</p> */}
          </div>

          <div className="lead-wrap comFeed">
            <p className="ttl">豊富な実績を誇る<br />長谷工グループの販売会社。</p>
            <p className="txt">長谷工アーベストは、マンション販売取扱395,000戸超の実績※をもつ、<br className='_pc' />長谷工グループの新築分譲マンション販売会社です。<br />事業主と事前に綿密な打合せを行い「市場に求められる住まい」を提案し、お客様に提供するお手伝いをしています。<br />事業主のマンション企画への思いをお客様に伝えるホットラインとなりながら、<br className='_pc' />これからも「最新の快適な住まい」を販売し、社会貢献してまいります。</p>
          </div>

          <div className="data-wrap">
            <div className="data-box">
              <div className="list l">
                <p className="ttl">2020年販売提携取扱戸数</p>
                <p className="main">No.1</p>
                {/* <p className="txt"></p> */}
                {/* <p className="note"></p> */}
              </div>
              <div className="list l">
                <p className="ttl">マンション販売取扱実績</p>
                <p className="main">39万<span>戸超</span></p>
                {/* <p className="txt"></p> */}
                {/* <p className="note"></p> */}
              </div>
            </div>
          </div>

          <div className="share-wrap">
            <div className="share-box">
              <div className="img-box">
                <p className="ttl">首都圏新規分譲マンション全体供給戸数と（株）長谷工アーベストのシェア</p>
                <div className="img">
                  <CompanyGraph
                    alt=""
                  />
                </div>
              </div>
              <p className="c-note">※シェア＝（株）長谷工アーベスト販売提携取り扱い戸数÷首都圏新規分譲マンション供給戸数<br />※対象となっている首都圏新規分譲マンション供給戸数は（株）不動産経済研究所から発表されたデータを基にしております。（公社・公団物件及び平均面積30㎡以下の物件を除く）<br />※（株）長谷工アーベスト販売提携取り扱い戸数については、右記条件にて算出しています。①総取り扱い戸数 （JV販売の按分はしておりません）②公社・公団物件を除く③東京、神奈川、埼玉、千葉物件対象<br />※「image photo」の表記がある写真は全てイメージです。</p>
            </div>

          </div>

        </div>
      </section>


      {/* CONTENTS */}
    </main>
  )
}

export default CompanyPage
